import React from "react";

import { RouteItem } from ".";

const QueuesListPage = React.lazy(
  () => import("pages/queues/list/QueuesListPage")
);

export const routes: RouteItem[] = [
  {
    path: "/queues",
    element: (
      <React.Suspense fallback={<></>}>
        <QueuesListPage />
      </React.Suspense>
    )
  }
];
