import React from "react";

import { RouteItem } from ".";

const ProductsListPage = React.lazy(
  () => import("pages/products/list/ProductsListPage")
);

const ProductViewPage = React.lazy(
  () => import("pages/products/view/ViewProductPage")
);

export const routes: RouteItem[] = [
  {
    path: "/products",
    element: (
      <React.Suspense fallback={<></>}>
        <ProductsListPage />
      </React.Suspense>
    )
  },
  {
    path: "/products/:id",
    element: (
      <React.Suspense fallback={<></>}>
        <ProductViewPage />
      </React.Suspense>
    )
  }
];
