import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { SortDirection } from "graphql/rails-api";

interface TableHeaderColProps {
  children?: React.ReactNode;
  sortKey?: string;
  currentSort?: {
    column: string;
    direction: SortDirection;
  };
  onSort?: (config: {
    column: string;
    direction: SortDirection | null;
  }) => void;
}

export default function TableHeaderCol({
  children = null,
  sortKey,
  currentSort,
  onSort
}: TableHeaderColProps) {
  if (!sortKey || !onSort) {
    return (
      <th
        className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-cello-500 sm:pl-0"
        scope="col"
      >
        {children}
      </th>
    );
  }

  const isSorted = currentSort?.column === sortKey;
  const currentDirection = isSorted ? currentSort.direction : null;

  let Icon = ChevronUpDownIcon;
  switch (currentDirection) {
    case SortDirection.Asc:
      Icon = ChevronUpIcon;
      break;
    case SortDirection.Desc:
      Icon = ChevronDownIcon;
      break;
  }

  const handleClick = () => {
    let nextDirection: SortDirection | null = SortDirection.Desc;

    if (currentDirection === SortDirection.Desc) {
      nextDirection = SortDirection.Asc;
    } else if (currentDirection === SortDirection.Asc) {
      nextDirection = null;
    }

    onSort({
      column: sortKey,
      direction: nextDirection
    });
  };

  return (
    <th
      className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-cello-500 sm:pl-0"
      scope="col"
    >
      <button
        className="group inline-flex items-center uppercase hover:text-cello-700"
        onClick={handleClick}
      >
        <Icon aria-hidden="true" className="mr-1 size-4" />
        {children}
      </button>
    </th>
  );
}
