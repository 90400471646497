import React from "react";

import { RouteItem } from ".";

const InventoryListPage = React.lazy(
  () => import("pages/inventory/list/InventoryListPage")
);

export const routes: RouteItem[] = [
  {
    path: "/inventory",
    element: (
      <React.Suspense fallback={<></>}>
        <InventoryListPage />
      </React.Suspense>
    )
  }
];
